import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthenticationContext from '../../contexts/auth/authentication-context';
import AuthLayout from '../../layouts/AuthLayout';

const LogoutPage = () => {
    const {logout} = useContext(AuthenticationContext);
    const history = useHistory();

    useEffect(() => {
        if (logout) {
            logout();
        }
    }, [logout]);

    return (
        <AuthLayout>
            <p>Logging out...</p>
        </AuthLayout>
    );
}

export default LogoutPage;