import { createContext } from 'react';
import { TSiteChannel } from '../site/siteContext';

export type TOrder = {
    ref: string;
    status: TOrderStatus;
    channel: TOrderChannel;
    wanted_at: string;
    customer: TCustomer;
    items: TOrderItem[];
    subtotal: number;
    adjustments: TOrderAdjustment[];
    total: number;
    notes: string;
    delivery: TOrderDelivery | null;
    table_no: number | null;
    locale: string;
    ccy: string;
}

export type TRefundData = {
    show: boolean;
    ref: string;
    stage: number;
    amount: number;
    reason: string | null;
    reasonExtra: string | null;
    loading: boolean;
    validInput: boolean;
}

export type TOrderStatus = 'new' | 'accepted' | 'completed' | 'cancelled'

type TOrderChannel = TSiteChannel;

export type TCustomer = {
    first_name: string;
    last_name: string;
    email: string;
    tel: string;
    address_1: string;
    address_2: string;
    address_city: string;
    address_postcode: string;
    cc_brand: string | null;
    cc_name: string;
    last_four: number;
    expiry_mth: number;
    expiry_year: number;
}

export type TOrderItem = {
    name: string;
    quantity: number;
    line_total: number;
    modifiers: string[]
}

type TOrderDelivery = {
    vendor: 'stuart' | 'uber';
    vendor_job_id: string;
    support_number: string;
    pickup_eta: string;
    status: 'new' | 'scheduled' | 'in_progress' | 'complete' | 'cancelled' | 'unknown';
    driver: string;
}

type TOrderAdjustment = {
    name: string;
    amount: number;
};

export type OrderContextData = {
    orders: TOrder[] | null;
    setOrders: (orders: TOrder[] | null) => void;
    getOrders: () => void;

    orderError: string | null;
    setOrderError: (err: string | null) => void;

    gridView: boolean;
    setGridView: (show: boolean) => void;

    order: TOrder | null;
    setOrder: (order: TOrder | null) => void;

    refundModal: TRefundData;
    setRefundModal: (refund: TRefundData) => void;
};

const OrderContext = createContext<OrderContextData | undefined>(undefined);

export default OrderContext;