import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../../assets/imgs/orderswift-logo-red.png';
import AuthenticationContext from '../../contexts/auth/authentication-context';
import AuthLayout from '../../layouts/AuthLayout';



const LoginPage = () => {
    const history = useHistory();
    const location = useLocation();
    const { login, authenticated } = useContext(AuthenticationContext);

    let { from }: any = location.state || { from: { pathname: "/" } };

    const [username, setUsername] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);

    const [error, setError] = useState<string | null>(null);

    const handleLogin = (username: string, password: string) => {
        setError(null);

        if (!login) {
            return;
        }
        return login(username, password, () => {
            setError(null);
            history.replace(from);
        }, (err: any) => {
            setError('These credentials do not match our records.');
        });
    }

    useEffect(() => {
        if (authenticated) {
            // console.log('you are authed, redirecting to', from)
            history.replace(from);
        }
    }, [authenticated]);

    return (
        <AuthLayout>
            <form className="form-horizontal" onSubmit={e => { e.preventDefault(); }}>

                <div className="col">
                    <p className="text-center" style={{ 'marginBottom': '36px' }}><img src={logo} alt="orderswift logo" width="190px" /></p>
                </div>

                <div className="form-group">
                    <div className="col">
                        <input id="username" type="username" className="form-control" name="username" placeholder="Username" onChange={e => setUsername(e.target.value)} />
                        {error ? (<span className="help-block"><strong>These credentials do not match our records.</strong></span>) : null}
                    </div>
                </div>

                <div className="form-group">

                    <div className="col">
                        <input id="password" type="password" className="form-control" name="password" placeholder="Password" onChange={e => setPassword(e.target.value)} /></div>
                </div>

                <div className="form-group">
                    <div className="col text-center">
                        <button className="btn btn-success btn-block" disabled={!(username && password)} onClick={(e) => (username && password) ? handleLogin(username, password) : setError('Username/Password missing.')}>
                            Login
                        </button>
                    </div>
                </div>
            </form>
        </AuthLayout>
    );
}

export default LoginPage;