import Cookies from 'js-cookie';
import api from './api';

export const getAccessToken = () => Cookies.get('os_access_token');
export const getRefreshToken = () => Cookies.get('os_refresh_token');
export const isAuthenticated = () => {
    return !!getAccessToken();
};

export type OAuthToken = {
    access_token: string;
    refresh_token: string;
    expires_in?: number;
}

export type OAuthError = {
    error: string;
    error_description: string;
    hint?: string;
    message: string;
}

export const setTokens = (data: OAuthToken) => {
    // console.log('setting access token to ', data.access_token,  { expires: data.expires_in })
    // console.log('setting refresh token to ', data.refresh_token)

    // Defaulting to 30 days expiry here in case of setting the value
    // from the cookie itself. Because you can't read a cookie's
    // expiry date, we need to set something otherwise it becomes
    // a Session cookie and closing the app/website will force
    // a logout, which is not the desired behavior..

    Cookies.set('os_access_token', data.access_token, { expires: data.expires_in ?? 30 });
    Cookies.set('os_refresh_token', data.refresh_token);
}

export const deleteTokens = () => {
    Cookies.remove('os_access_token');
    Cookies.remove('os_refresh_token');
}

export function userLogin(email: string, password: string, callback: () => void, errorcb: (error: OAuthError) => void) {

    api.post('oauth/token', {
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        username: email,
        password: password,
    }).then((response) => {

        setTokens(response.data);
        typeof callback === 'function' && callback();

    }).catch((error) => {
        console.log(error.message);
        typeof errorcb === 'function' && errorcb(error);

    });

}

export function logout(callback: () => void) {
    deleteTokens();
    callback();
}