import React, { useContext } from 'react';
import MenuContext from './menu-context';

const useMenuContext = () => {
    const context = useContext(MenuContext);

    if (context === undefined) {
        throw new Error('useMenuContext must be within a MenuProvider');
    }
    
    return context;
}

export default useMenuContext;