import React, {useEffect, useState} from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css"; // optional styles
import {TOrder, TRefundData} from '../../../../contexts/orders/order-context';
import useOrderContext from '../../../../contexts/orders/useOrderContext';
import api from '../../../../services/api';
import { money } from '../../../../services/format';


type RefundModalProps = {
    refund: TRefundData;
    setRefundModal: (refund: TRefundData) => void;
    handleClose: () => void;
    order: TOrder;
}

const RefundModal = ({ refund, setRefundModal, handleClose, order }: RefundModalProps) => {
    const orderContext = useOrderContext();
    const [title, setTitle] = useState<string | null>(null)
    const [content, setContent] = useState<JSX.Element | null>(null);
    const [footer, setFooter] = useState<JSX.Element | null>(null);

    const [validInput, setValidInput] = useState(false);

    const setStage = (stage: number) => {
        setRefundModal({
            ...orderContext.refundModal,
            stage: stage
        });
    };

    const setAmount = (amount: number) => {
        setRefundModal({
            ...orderContext.refundModal,
            amount: amount
        });
    };

    const setReason = (reason: string) => {
        setRefundModal({
            ...orderContext.refundModal,
            reason: reason
        });
    };

    const setReasonExtra = (extra: string) => {
        setRefundModal({
            ...orderContext.refundModal,
            reasonExtra: extra
        });
    };

    const setLoading = (loading: boolean) => {
        orderContext.setRefundModal({
            ...orderContext.refundModal,
            loading: loading
        });
    };

    useEffect(() => {
        if (!refund.amount || !refund.reason) {
            setValidInput(false);
        } else if ((refund.amount && refund.reason && refund.reason === 'requested_by_customer') && !refund.reasonExtra) {
            setValidInput(false);
        } else {
            setValidInput(true);
        }

    }, [refund.amount, refund.reason, refund.reasonExtra]);

    const successfulRefund = () => {
        orderContext.setRefundModal({
            ...orderContext.refundModal,
            loading: false,
            stage: 2
        });
    }
    
    const postRefundCleanup = () => {
        handleClose();
        orderContext.getOrders();
    }

    useEffect(() => {
        setFooter(null);
        switch (refund.stage) {
            case 1:
                setTitle('Confirm Refund');
                setContent(<>
                    <p>Please click the ‘Authorise’ button below to confirm the refund.</p>
                    <p>Refund of <b>{money(refund.amount * 100, order.ccy, order.locale)}</b> to the customer’s card.</p>
                </>)
                setFooter((<>
                    <Button variant="light" onClick={() => setStage(0)} disabled={refund.loading} block>
                        BACK
                    </Button>
                    <Button variant="danger" onClick={() => {
                        // disable
                        setLoading(true);
                        // make stripe request
                        api.put('api/app/refund', {
                            ref: order.ref,
                            amount: refund.amount,
                            reason: refund.reason,
                            reason_extra: refund.reasonExtra ?? '',
                            payment_type: 'card',
                        })
                            .then(async () => {
                                successfulRefund();
                            })
                            .catch((error: any) => {
                                let message = 'An error occurred while processing the refund.';
                                
                                if (error.response.data.stripe !== undefined) {
                                    message = error.response.data.stripe;
                                }

                                if (error.response.data.error !== undefined) {
                                    message = error.response.data.error;
                                }

                                if (error.response.data.message !== undefined) {
                                    message = error.response.data.message;
                                }

                                toaster.notify("Error: " + message, {
                                    duration: 7000
                                });
                                console.error(error);
                                setLoading(false);

                            });
                        // enable
                    }} disabled={refund.loading} block>
                        {refund.loading ? <i className="fa fa-spinner fa-pulse"></i> : 'AUTHORISE'}
                    </Button>
                </>));
                break;

            case 2:
                setTitle('Refund Confirmed');
                setContent(<p>The refund has been made. The refund will appear on the customer’s card within 5 working days.</p>)
                setFooter((<>
                    <Button variant="info" onClick={postRefundCleanup} block>
                        CLOSE
                    </Button>
                </>));
                break;

            default:
                setTitle('Refund Order (' + order.ref.substr(-4) + ')');
                setContent(() => <Form.Group className={"mb-0"}>
                    <Form.Row>
                        <Form.Label column lg={4}>
                            Refund Amount:
                      </Form.Label>
                        <Col>
                            <Form.Control type="text" name="amount"
                                onBlur={(e: any) => {
                                    setAmount(Number(e.target.value));
                                }}
                                defaultValue={refund.amount}
                            />

                            {/*<NumberFormat className="form-control"
                                autoFocus={true}
                                value={refund.amount}
                                defaultValue={order.total / 100}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={''}
                                allowNegative={false}
                                isAllowed={(value) => Number(value.value) <= order.total / 100}
                                onValueChange={(value) => {
                                    setAmount(Number(value.value));
                                }} />*/}

                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column lg={4}>
                            Refund Reason:
                        </Form.Label>
                        <Col>
                            <Form.Control
                                as="select"
                                className="my-1 mr-sm-2"
                                id="inlineFormCustomSelectPref"
                                name="reason" onChange={(e) => setReason(e.target.value)}
                                value={(refund.reason ?? '') as any}
                                //defaultValue=""
                                custom
                            >
                                <option value="" disabled>Choose...</option>
                                {REFUND_REASONS.map(({ name, displayName }) => <option key={name + 'reason'} value={name}>{displayName}</option>)}
                            </Form.Control>
                            {refund.reason === 'requested_by_customer' ?
                                <Form.Control
                                    type="text"
                                    name="reason_extra"
                                    onChange={(e) => setReasonExtra(e.target.value)}
                                    value={refund.reasonExtra as any}
                                    placeholder={"Input reason..."}
                                /> : null}
                        </Col>
                    </Form.Row>
                </Form.Group>)
                setFooter((<>
                    <Button variant="info" onClick={() => setStage(refund.stage + 1)} disabled={!validInput} block>
                        CONFIRM
                    </Button>
                </>));
        }
    //}, [refund.stage, refund.amount, refund.reason, refund.reasonExtra, validInput, refund.loading]);
    }, [ refund.stage, validInput ]);

    return (
        <Modal show={refund.show} onHide={handleClose} animation={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>{content}</Modal.Body>

            <Modal.Footer>
                {footer}
            </Modal.Footer>
        </Modal>
    );
}

const REFUND_REASONS = [
    { name: 'duplicate', displayName: 'Duplicate' },
    { name: 'fraudulent', displayName: 'Fraudulent' },
    { name: 'requested_by_customer_cancellation', displayName: 'Requested by customer: cancellation' },
    { name: 'requested_by_customer_late_delivery', displayName: 'Requested by customer: late delivery' },
    { name: 'requested_by_customer_missing_food', displayName: 'Requested by customer: missing food' },
    { name: 'requested_by_customer_unsatisfactory_order', displayName: 'Requested by customer: unsatisfactory order' },
    { name: 'requested_by_customer_no_delivery_driver_available', displayName: 'Requested by customer: no delivery driver available' },
    { name: 'requested_by_customer', displayName: 'Other' }
]

export default RefundModal;