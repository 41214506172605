import { useEffect, useState } from 'react';
import api from '../../services/api';
import { deleteTokens, getAccessToken, getRefreshToken, OAuthError, OAuthToken, setTokens as setCookieTokens } from '../../services/auth';

const useAuthentication = () => {
    const [authenticated, setAuthenticated] = useState(true);
    const [tokens, setTokens] = useState<OAuthToken | null>(null);
    const [errors, setErrors] = useState<OAuthError | null>(null);

    // Initialize with cookie tokens
    useEffect(() => {
        let access = getAccessToken();
        let refresh = getRefreshToken();

        if (access && refresh && !tokens) {
            setTokens({
                access_token: access,
                refresh_token: refresh
            })
            setAuthenticated(true);
        }
    }, []);

    // Determine whether new tokens are valid
    useEffect(() => {
        if (tokens) {
            setCookieTokens(tokens);
            if (!authenticated) {
                setAuthenticated(true)
            }
        }
    }, [tokens])

    const login = (username: string, password: string, callback: () => void, errorCallback?: (err: any) => void) => {

        api.post('oauth/token', {
            grant_type: 'password',
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            username: username,
            password: password,
        }).then((response) => {
            setTokens(response.data);
            setAuthenticated(true);
            callback();

        }).catch((error) => {
            setAuthenticated(false);

            console.log(error);
            setErrors(error);

            if (errorCallback) {
                errorCallback(error)
            }

        });
    }

    const logout = (callback?: () => void) => {
        deleteTokens();
        setTokens(null);
        setAuthenticated(false);
        if (callback) {
            callback();
        }
    }

    return {
        authenticated,
        setAuthenticated,
        tokens,
        login,
        logout,
    }
}

export default useAuthentication