import React, { createContext } from 'react';
import { OAuthToken } from '../../services/auth';

export type AuthenticationContextData = {
    authenticated: boolean;
    setAuthenticated: (authenticated: boolean) => void;
    tokens: OAuthToken | null;
    setTokens: (token: OAuthToken | null) => void;
    login: (username: string, password: string, callback: () => void, errorCallback?: (error: any) => void) => void;
    logout: () => void;
} 

const AuthenticationContext = createContext<Partial<AuthenticationContextData>>({
    authenticated: false,
    tokens: null,
    login: (username: string, password: string) => {}
});

export default AuthenticationContext;