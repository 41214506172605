import React, { useEffect, useRef, useState } from 'react';

type OrderViewLayoutProps = {
    footer: JSX.Element[] | null;
    children: JSX.Element;
}

const OrderViewLayout = ({ children, footer }: OrderViewLayoutProps) => {
    const [displayArrow, setDisplayArrow] = useState(false);
    const orderElement = useRef<HTMLDivElement>(null);
    const [offset, setOffset] = useState(0);

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        const scroll = e.target.scrollTop;

        if ( scroll > 1) {
            sessionStorage.setItem('scrollPosition', scroll);
        }

        if (bottom) {
            setDisplayArrow(false);
        } else if (!displayArrow) {
            setDisplayArrow(true);
        }
    }

    useEffect(() => {
        const scrollPosition = sessionStorage.getItem('scrollPosition') ?? '1';

        // Slight hack to ensure scroll arrow is shown
        if (orderElement && parseInt(scrollPosition) === 1) {
            orderElement?.current?.scrollBy(0, 1);
            sessionStorage.setItem('scrollPosition', '1');
        }

        if (orderElement && parseInt(scrollPosition) > 1) {
            orderElement?.current?.scrollBy(0, parseInt(scrollPosition));
        }

    }, [orderElement])

    return (
        <div className="h-100">
            <div className="container py-3 h-100 wrapper--orders">
                <div className={'wrapper--orders__content ' + (displayArrow ? ' shadow' : '')}>
                    <div className={'h-100 container pb-4'} style={{ overflowY: 'scroll' }} onScroll={handleScroll} ref={orderElement}>
                        {children}
                    </div>

                    {displayArrow ? (
                        <div className="wrapper--orders__content__arrow">
                            <div className="fa fa-arrow-down"></div>
                        </div>
                    ) : false}
                </div>
                {footer}
            </div>
        </div>
    );
}

export default OrderViewLayout;