import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoginPage from '../pages/Authentication/LoginPage';
import LogoutPage from '../pages/Authentication/LogoutPage';
import FaqsPage from '../pages/Faqs/FaqsPage';
import MenuPage from '../pages/Menus/MenuPage';
import OrdersPage from '../pages/Orders/OrdersPage';
import SettingsPage from '../pages/settings/SettingsPage';
import PrivateRoute from './PrivateRoute';


const Routes = () => {
	return (
		<Router>
			<Switch>
				<Route path="/login">
					<LoginPage />
				</Route>

				<Route path="/logout">
					<LogoutPage />
				</Route>

				<PrivateRoute path="/faqs">
					<FaqsPage />
				</PrivateRoute>

				<PrivateRoute path="/settings">
					<SettingsPage></SettingsPage>
				</PrivateRoute>

				<PrivateRoute path="/menu/collection">
					<MenuPage channel="collection"></MenuPage>
				</PrivateRoute>

				<PrivateRoute path="/menu/delivery">
					<MenuPage channel="delivery"></MenuPage>
				</PrivateRoute>

				<PrivateRoute path="/menu/dine-in">
					<MenuPage channel="dine_in"/>
				</PrivateRoute>

				<PrivateRoute path="/menu/modifiers">
					<MenuPage channel={undefined} showModifiers={true}></MenuPage>
				</PrivateRoute>

				<PrivateRoute path="/orders/:ref">
					<OrdersPage></OrdersPage>
				</PrivateRoute>

				<PrivateRoute path="/">
					<OrdersPage></OrdersPage>
				</PrivateRoute>
			</Switch>
		</Router>
	);
}

export default Routes;