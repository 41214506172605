import React from 'react';
import Moment from 'react-moment';
import { Link, useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { TOrder } from '../../../contexts/orders/order-context';

type OrderCardProps = {
    order: TOrder;
}

const OrderCard = ({ order }: OrderCardProps) => {
    const selected = useLocation().pathname.indexOf(order.ref) !== -1;
    return (
        <Link to={{ pathname: "/orders/" + order.ref, search: useLocation().search }} className="p-0">
            <Card className={['card mt-3 ml-3 d-inline-block', 'card--' + order.status, (selected ? 'card--selected' : ''), 'card--' + order.channel].join(' ')}>
                <div className="card-body p-2">
                    <div className="card__tag">{order.status === 'cancelled' ? 'Cancelled' : order.channel === 'dine_in' ? 'Dine-In' : order.channel} {order.channel === 'delivery' ? <span className="fa fa-lg fa-motorcycle pull-right"></span> : null}{order.channel === 'dine_in' ? <span className="fa fa-lg fa-cutlery pull-right"></span> : null}</div>

                    <div className="card__name">{order.channel !== 'dine_in' ? (<span>{order.customer.first_name} {order.customer.last_name}</span>) : null}{(order.table_no !== null && order.channel === 'dine_in') ? <Moment format="HH:mm" className="card__timevalue">{order.wanted_at}</Moment> : null}</div>
                    <div className="mt-3 mb-2">
                        {order.channel === 'dine_in' ? (<span className="card__timevalue card__timevalue--hour">Table {order.table_no}</span>) : (
                            <React.Fragment>
                                <Moment format="HH:mm" className="card__timevalue card__timevalue--hour">{order.wanted_at}</Moment>
                                <Moment format="A" className="card__timevalue card__timevalue--period">{order.wanted_at}</Moment>
                            </React.Fragment>

                        )}
                    </div>
                    <p className="card__ago"><Moment fromNow>{order.wanted_at}</Moment></p>
                    <p className="dev__order-ref"><span className="text-white">{order.ref.substr(-4)}</span></p>
                </div>
            </Card>
        </Link>
    );
}

const flash = keyframes`
from {
    background-color: #2BBADC;
    color: #FFFFFF !important;
}

to {
    background-color: #2bbadcb0;
    color: #2BBADC !important;
}
`;


const Card = styled.div`
    min-height: 10rem;
    background-color: #E62123;
    border-radius: 10px;
    border: 3px solid transparent;

    &.card--selected {
        border: 3px solid black !important;
    }
    
    &.card--completed {
        background-color: #E62123;
    }

    &.card--cancelled {
        background-color: gray;
    }
    
    &.card--new {
        background-color: #2BBADC;
        animation: ${flash} 0.5s linear infinite;
       
    }

    &.card--dine_in {
        background-color: #00B869;
       
    }
    
    .card__tag {
        font-size: 0.875rem;
        text-transform: uppercase;
        color: white;
        letter-spacing: 1px;
        font-weight: 500;
    }
    .card__name {
        font-size: 1.5rem;
        font-weight: 300;
        color: #ffffff;
        opacity: 0.9;
    }
    .card__timevalue {
        color: #ffffff;
        display: inline-block;
        vertical-align: top;
    }
    .card__timevalue--hour {
        font-size: 3rem;
        line-height: 40px;
    }
    .card__timevalue--period {
        font-size: 14px;
    }
      
    
   
    .card__ago {
        color: #ffffff;
        opacity: 0.9;
        font-size: 1rem;
        margin-bottom: 0;
        font-weight: 300;
    }
    .dev__order-ref {
        position: absolute;
        bottom: 5px;
        right: 5px;
        background-color: rgba(255,255,255,0.3);
        padding: 3px 7px;
        border-radius: 4px;
        margin: 0;
        a {
            color: #ffffff !important;
            text-decoration: none;
            padding: 0;
        }
        font-size: 0.6em;
    }
  
    
    
`;

export default OrderCard;