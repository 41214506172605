import React, { useContext, useEffect } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import AuthenticationContext from '../contexts/auth/authentication-context';
import MenuProvider from '../contexts/menu/MenuProvider';
import OrderProvider from '../contexts/orders/OrderProvider';
import SiteContextProvider from '../contexts/site/SiteContextProvider';

type PrivateRouteProps = {
    children: JSX.Element;
}

const PrivateRoute = (props: PrivateRouteProps & RouteProps) => {
    const history = useHistory();
    const auth = useContext(AuthenticationContext);

    // Logout on change in auth status
    useEffect(() => {
        // console.log('private route auth check' , auth.authenticated, history)
        if (auth.authenticated === false) {
            history.push('/login');
        }
    }, [auth.authenticated]);


    if (auth.authenticated) {
        return (
            <Route {...props}>
                <SiteContextProvider>
                    <OrderProvider>
                        <MenuProvider>
                            {props.children}
                        </MenuProvider>
                    </OrderProvider>
                </SiteContextProvider>
            </Route>
        );
    }

    return (
        <Redirect to={{
            pathname: "/login",
            state: { from: props.location }
        }} />
    )

}

export default PrivateRoute;