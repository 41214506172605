import React, { MouseEvent } from 'react';

type SwitchProps = {
    name: string;
    checked: boolean;
    handleClick: (switchStatus: boolean) => void
}

const Switch = ({ name, checked, handleClick }: SwitchProps) => {
    const clickHandler = (event: MouseEvent) => {
        event.preventDefault();
        handleClick(!checked);
    }

    return (
        <div className="switch-row">
            <p>{name}</p>
            <label className="switch" onClick={clickHandler}>
                <input type="checkbox" checked={checked} readOnly className="toggled"/>
                <span className="slider round"></span>
            </label>
        </div>
    );
}

export default Switch;