import React from 'react';
import {TOrder, TRefundData} from '../../../../contexts/orders/order-context';
import useOrderContext from '../../../../contexts/orders/useOrderContext';
import RefundModal from './RefundModal';

type RefundButtonProps = {
    order: TOrder;
    refundModal: TRefundData;
    setRefundModal: (refund:TRefundData) => void;
}

const RefundButton = ({ order, refundModal, setRefundModal }: RefundButtonProps) => {
    const orderContext = useOrderContext();

    const handleClose = () => {
        setRefundModal({
            show: false,
            ref: order.ref,
            stage: 0,
            amount: (order.total ?? 0) / 100,
            reason: '',
            reasonExtra: '',
            loading: false,
            validInput: false
        });
    }

    const handleShow = () => {
        let hasGiftCard = orderContext.order?.adjustments.find((adjustment) => {
            return adjustment.name.toLocaleLowerCase().search('gift') !== -1;
        });
        if (hasGiftCard) {
            alert('As this order was fully or part paid for by a giftcard please contact support@orderswift.com quoting the order reference to make a refund.')
        } else {
            setRefundModal({
                show: true,
                ref: order.ref,
                stage: 0,
                amount: (order.total ?? 0) / 100,
                reason: '',
                reasonExtra: '',
                loading: false,
                validInput: false
            });
        }
    };

    return (
        <>
            <RefundModal
                refund={refundModal}
                setRefundModal={setRefundModal}
                handleClose={handleClose}
                order={order}
            />
            <button className="btn btn-info btn-lg btn-block" onClick={handleShow} disabled={!order.total}><b>REFUND</b></button>
        </>
    )
}

export default RefundButton;
