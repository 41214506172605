import React, { useContext } from 'react';
import Select from '../../../components/form/Select';
import SiteContext from '../../../contexts/site/siteContext';

const Capacity = () => {

    const siteContext = useContext(SiteContext);

    const changeCapacityCollection = (value: number) => {
        siteContext.updateSite({ ...siteContext.site, capacity_collection: value });
    }
    const changeCapacityDelivery = (value: number) => {
        siteContext.updateSite({ ...siteContext.site, capacity_delivery: value });
    }

    if ( !siteContext.site || ( !siteContext.site.see_capacity_collection && !siteContext.site.see_capacity_delivery ) ) {
        return null;
    }

    let capacityOptionsCollection: any = {
        99: 'No Limits',
        1: '1 order',
        2: '2 orders',
        3: '3 orders',
        4: '4 orders',
        5: '5 orders',
        10: '10 orders',
        15: '15 orders',
        20: '20 orders',
        25: '25 orders',
        30: '30 orders',
        35: '35 orders',
        40: '40 orders',
        45: '45 orders',
        50: '50 orders'
    };

    let capacityOptionsDelivery: any = {
        99: 'No Limits',
        1: '1 order',
        2: '2 orders',
        3: '3 orders',
        4: '4 orders',
        5: '5 orders',
        10: '10 orders',
        15: '15 orders',
        20: '20 orders',
        25: '25 orders',
        30: '30 orders',
        35: '35 orders',
        40: '40 orders',
        45: '45 orders',
        50: '50 orders'
    };

    // If current value is not in prepOptions, then append as a new option
    if ( !(siteContext.site.capacity_collection in capacityOptionsCollection)) {
        capacityOptionsCollection = {
            ...capacityOptionsCollection,
            [siteContext.site.capacity_collection]: `${siteContext.site.capacity_collection} orders`
        }
    }
    if ( !(siteContext.site.capacity_delivery in capacityOptionsDelivery)) {
        capacityOptionsDelivery = {
            ...capacityOptionsDelivery,
            [siteContext.site.capacity_delivery]: `${siteContext.site.capacity_delivery} orders`
        }
    }

    const optionsCollection = Object.keys(capacityOptionsCollection).map((key) => (
        <option key={key} value={key}>{capacityOptionsCollection[key] as typeof capacityOptionsCollection}</option>
    ));

    const optionsDelivery = Object.keys(capacityOptionsDelivery).map((key) => (
        <option key={key} value={key}>{capacityOptionsDelivery[key] as typeof capacityOptionsDelivery}</option>
    ));

    return (
        <>
            <b className="text-muted">Orders per time slot</b>

            { siteContext.collectionEnabled && siteContext.site.see_capacity_collection ?
                <Select
                    label="Collection"
                    options={optionsCollection}
                    value={siteContext.site.capacity_collection}
                    handleChange={changeCapacityCollection}
                /> : null}

            { siteContext.deliveryEnabled && siteContext.site.see_capacity_delivery ?
                <Select
                    label="Delivery"
                    options={optionsDelivery}
                    value={siteContext.site.capacity_delivery}
                    handleChange={changeCapacityDelivery}
                /> : null }

            <hr/>
        </>
    );
}

export default Capacity;