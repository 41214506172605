import React from 'react';
import { TSiteChannel } from '../../contexts/site/siteContext';
import AppLayout from '../../layouts/app/AppLayout';
import MenuTabs from './components/MenuTabs';
import MenuView from './components/MenuView';
import ModifierView from './components/ModifierView';

type MenuPageProps = {
    channel?: TSiteChannel;
    showModifiers?: boolean;
}

const MenuPage = ({ channel, showModifiers = false }: MenuPageProps) => {
    return (
        <AppLayout>
            <div className="col view--settings">
                <div className="container wrapper--settings">
                    <MenuTabs channel={channel} showModifiers={showModifiers}></MenuTabs>

                    <div className="component-light">
                        {showModifiers ? <ModifierView /> : (channel ? <MenuView channel={channel} /> : null)}
                    </div>
                </div>
            </div>
        </AppLayout>

    );
}

export default MenuPage;