import React, { useContext } from 'react';
import Select from '../../../components/form/Select';
import SiteContext from '../../../contexts/site/siteContext';

const PrepSettings = () => {
    const siteContext = useContext(SiteContext);

    const changeCollectionPrepTime = (value: number) => {
        siteContext.updateSite({ ...siteContext.site, collection_prep_time: value });
    }

    const changeDeliveryPrepTime = (value: number) => {
        siteContext.updateSite({ ...siteContext.site, delivery_prep_time: value });
    }

    if ( !siteContext.site || !siteContext.site.see_prep_time) {
        return null;
    }

    let prepOptions: any = {
        5: '5 Minutes',
        10: '10 Minutes',
        12: '12 Minutes',
        15: '15 Minutes',
        20: '20 Minutes',
        25: '25 Minutes',
        30: '30 Minutes',
        35: '35 Minutes',
        40: '40 Minutes',
        45: '45 Minutes',
        50: '50 Minutes',
        55: '55 Minutes',
        60: '60 Minutes',
        90: '90 Minutes',
        120: '120 Minutes',
        150: '150 Minutes',
        180: '180 Minutes',
        360: '6 Hours',
        720: '12 Hours',
        1440: '24 Hours',
        2880: '48 Hours',
    };



    // If current value is not in prepOptions, then append as a new option
    if (siteContext.collectionEnabled && !(siteContext.site.collection_prep_time in prepOptions)) {
        prepOptions = {
            ...prepOptions,
            [siteContext.site.collection_prep_time]: `${siteContext.site.collection_prep_time} Minutes`
        }
    }

    if (siteContext.deliveryEnabled && !(siteContext.site.delivery_prep_time in prepOptions)) {
        prepOptions = {
            ...prepOptions,
            [siteContext.site.delivery_prep_time]: `${siteContext.site.delivery_prep_time} Minutes`
        }
    }
    const options = Object.keys(prepOptions).map((key) => (
        <option key={key} value={key}>{prepOptions[key] as typeof prepOptions}</option>
    ));

    return (
        <>
            <b className="text-muted">Preparation Time</b>

            {siteContext.collectionEnabled ?
                <Select label="Collection" options={options} value={siteContext.site.collection_prep_time} handleChange={changeCollectionPrepTime} />
                : null}
            {siteContext.deliveryEnabled ?
                <Select label="Delivery" options={options} value={siteContext.site.delivery_prep_time} handleChange={changeDeliveryPrepTime} />
                : null}

            <hr/>
        </>
    );
}

export default PrepSettings;