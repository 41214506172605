import React from 'react';
import Moment from 'react-moment';
import { Link, useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { TOrder } from '../../../contexts/orders/order-context';

type OrderPillProps = {
    order: TOrder;
}

function OrderPill({ order }: OrderPillProps) {
    const selected = useLocation().pathname.indexOf(order.ref) !== -1;
    return (
        <Link to={{ pathname: "/orders/" + order.ref, search: useLocation().search }} className="p-0">
            <Pill className={[' mt-1 ml-3 p-2 d-inline-block', 'card--' + order.status, selected ? 'card--selected' : '', 'card--' + order.channel].join(' ')}>
                <table style={{ width: '100%' }}>
                    <colgroup>
                        <col span={1} style={{ width: '15%' }} />
                        <col span={1} style={{ width: '60%' }} />
                        <col span={1} style={{ width: '25%' }} />
                    </colgroup>

                    <tbody>
                        <tr>
                            <td>
                                <Moment format="HH:mm" className="card__timevalue">{order.wanted_at}</Moment>
                                <Moment format="A" className="card__timevalue">{order.wanted_at}</Moment>
                            </td>
                            <td >{order.channel !== 'dine_in' ? (order.customer.first_name + " " + order.customer.last_name) : null}{(order.table_no !== null && order.channel === 'dine_in') ? <span>Table {order.table_no}</span> : null}</td>
                            <td style={{ textAlign: 'right' }}><span className="card__channel">{order.status === 'cancelled' ? 'CANCELLED' : order.channel !== 'dine_in' ? order.channel.toUpperCase() : 'DINE-IN'}</span> {order.status === 'new' ? (<React.Fragment>&emsp; <span className="badge badge-light">NEW</span></React.Fragment>) : null}</td>
                        </tr>
                    </tbody>
                </table>
            </Pill>
        </Link>
    );
}

const flash = keyframes`
from {
    background-color: #2BBADC;
    color: #FFFFFF !important;
}

to {
    background-color: #2bbadcb0;
    color: #2BBADC !important;
}
`;


const Pill = styled.div`
    background-color: #E62123;
    border-radius: 5px;
    border: 3px solid transparent;
    width: calc(100% - 30px);

    color: #ffffff !important;
    text-decoration: none;

    &.card--selected {
        border: 2px solid black !important;
    }
    
    &.card--completed {
        background-color: #E62123;
    }

    &.card--cancelled {
        background-color: gray;
    }
    
    &.card--new {
        background-color: #2BBADC;
        animation: ${flash} 0.5s linear infinite;
       
    }

    &.card--dine_in {
        background-color: #00B869;
       
    }
    
    .card__tag {
        font-size: 0.875rem;
        text-transform: uppercase;
        color: white;
        letter-spacing: 1px;
        font-weight: 500;
    }
    .card__name {
        font-size: 1.5rem;
        font-weight: 300;
        color: #ffffff;
        opacity: 0.9;
    }
    .card__timevalue {
        color: #ffffff;
        display: inline-block;
        vertical-align: top;
    }
    .card__timevalue--hour {
        font-size: 3rem;
        line-height: 40px;
    }
    .card__timevalue--period {
        font-size: 14px;
    }

    .card__channel {
        font-size: 12px;
    }
      
    
   
    .card__ago {
        color: #ffffff;
        opacity: 0.9;
        font-size: 1rem;
        margin-bottom: 0;
        font-weight: 300;
    }
    .dev__order-ref {
        position: absolute;
        bottom: 5px;
        right: 5px;
        background-color: rgba(255,255,255,0.3);
        padding: 3px 7px;
        border-radius: 4px;
        margin: 0;
        a {
            color: #ffffff !important;
            text-decoration: none;
            padding: 0;
        }
        font-size: 0.6em;
    }
  
    
    
`;

export default OrderPill;