import React from 'react';
import Page from '../pages/Page';

type AuthLayoutProps = {
    children: JSX.Element
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
    return (
		<div className="row justify-content-center align-items-center m-0" style={{height: '100vh'}}>
            <div className="col-12 col-lg-4 col-md-8 col-sm-8 colmx-lg-auto">
                <Page>
                    {children}
                </Page>
            </div>
        </div>
    );
}

export default AuthLayout;