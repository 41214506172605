import React from 'react';
import Navbar from '../app/components/Navbar';

type OrdersLayoutProps = {
	banner: JSX.Element | null;
	children: JSX.Element;
	orderView: JSX.Element;
}

const OrdersLayout = ({ banner, children, orderView }: OrdersLayoutProps) => {

	return (
		<div className="app-container">
			{banner}

			<div className="row row-eq-height h-100">
				<div className="pane pane--nav">
					<Navbar></Navbar>
				</div>
				<div className="col view--orders">
					{children}
				</div>
				<div className="pane pane--orders h-100">
					{orderView}
				</div>
			</div>
		</div>
	);
}

export default OrdersLayout;
