import axios from 'axios';
import { getAccessToken, getRefreshToken, setTokens } from './auth';

const API_URL = process.env.REACT_APP_BASE_URL;
// console.log(process.env);
const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true
});

// Add a request interceptor
api.interceptors.request.use(
    config => {
        const token = getAccessToken();

        if (token) {
            // console.log('Access token detected', token);
            config.headers['Authorization'] = 'Bearer ' + token;
        } else {
            console.warn('token not found');
        }

        return config;
    },
    error => {
        Promise.reject(error)
    }
);


api.interceptors.response.use(
    (response) => (response),
    (error) => {
        const originalRequest = error.config;

        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */

            if (error.response.status === 401 && originalRequest.url.indexOf('auth/token') !== -1) {
                //console.log('REDIRECT TO LOGIN');
                window.location.replace("/logout");
                // history.push('/logout');
                return Promise.reject(error);
            } else if (error.response.status === 401 && !originalRequest._retry) {
                console.log('Attempting refresh');

                originalRequest._retry = true;
                const refreshToken = getRefreshToken();

                if (!refreshToken) {
                    // console.log('No refresh token');
                    window.location.replace("/logout");

                    return Promise.reject(error);
                }

                return api.post('/oauth/token', {
                    grant_type: 'refresh_token',
                    client_id: process.env.REACT_APP_CLIENT_ID,
                    client_secret: process.env.REACT_APP_CLIENT_SECRET,
                    refresh_token: refreshToken
                })
                    .then(res => {
                        // console.log('refresh response', res)
                        if (res && res.status === 200) {
                            console.log('Token refreshed!', res)
                            setTokens(res.data);
                            api.defaults.headers.common['Authorization'] = 'Bearer ' + getAccessToken();
                            return api(originalRequest);
                        }
                    })
            }
        }

        return Promise.reject(error);
    }
);

export default api;