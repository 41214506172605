import React from 'react';
import Moment from 'react-moment';
import LoadingWheel from '../../components/misc/LoadingWheel';
import { TOrder } from '../../contexts/orders/order-context';
import useOrderContext from '../../contexts/orders/useOrderContext';
import { money } from '../../services/format';
import OrderCard from './components/OrderCard';
import OrderPill from './components/OrderPill';
import { useQuery } from './components/OrdersViewNav';

type OrderViewProps = {
    orders: TOrder[] | null;
}

const OrdersView = ({ orders }: OrderViewProps) => {
    const orderContext = useOrderContext();
    const filter = useQuery().get('filter');

    if (orders === null) {
        return (<LoadingWheel />);
    }

    if (orders.length === 0) {
        return (
            <div className="row justify-content-center align-self-center">
                <h2><span className="badge badge-dark">No Orders</span></h2>
            </div>
        );
    }

    const isActiveScreen = filter === 'active' || !filter
        ? true : false;

    const renderOrderPills = (grid: Boolean = false) => {
        let currency: any, locale: any;
        const groups = orders.reduce((groups: any, order) => {
            currency = order.ccy;
            locale = order.locale;

            const date = order.wanted_at.split('T')[0];

            if (!groups['new']) {
                groups['new'] = [];
            }
            if (!groups[date]) {
                groups[date] = [];
            }
            // if (order.status === 'new') {
            //     groups['new'].push(order)
            // } else {
                groups[date].push(order);
            // }
            return groups;
        }, {});

        // Edit: to add it in the array format instead
        const orderGroups = Object.keys(groups).map((date) => {
            return {
                date,
                orders: groups[date],
                count: groups[date].length,
                amount: groups[date].length ? groups[date].reduce((total: any, current: any) => (parseInt(total ?? 0) + parseInt(current.total ?? 0)), 0) : 0
            };
        });
    
        let c = orderGroups.sort((a, b) => {
            if (a.date === 'new') {
                return 1;
            }
            if (isActiveScreen) {
                return (a.date > b.date) ? 1 : -1;
            }
            return (a.date < b.date) ? 1 : -1;
        })


        return orderGroups.map((group, index) => {
            return (
                <div className="mt-1 mb-3" key={index + 'group'}>
                    {group.date === 'new' ? <p></p> : (
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td><b className="ml-3"><Moment format="dddd, MMMM D YYYY">{group.date}</Moment> </b></td>
                                    <td style={{ textAlign: 'right' }}><span className="mr-3 text-muted">{group.count} {group.count === 1 ? 'order' : 'orders'} {money(group.amount, currency, locale)}</span></td>
                                </tr>
                            </tbody>
                        </table>


                    )}
                    {
                        group.orders.map((order: TOrder) => grid ? (
                            <OrderPill key={order.ref} order={order}></OrderPill>
                        ) : (
                            <OrderCard key={order.ref} order={order}></OrderCard>
                        ))
                    }
                </div>
            )
        })

    }
    return (
        <>

        <div className="wrapper--orders">
            <hr style={{ margin: 0 }}></hr>
        
            {/* <ChannelIndicator></ChannelIndicator> */}
            {
                renderOrderPills(orderContext.gridView)
            }
            <hr></hr>
        </div>
        </>

    )
}

export default OrdersView;