import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../../assets/imgs/icons/orderswift_logo.svg';
import useAuthentication from '../../../contexts/auth/useAuthentication';
import SiteContext from '../../../contexts/site/siteContext';

const Navbar = () => {
    const siteContext = useContext(SiteContext);
    const { logout } = useAuthentication()
    const history = useHistory();

    let link = "/";

    if (siteContext.site) {
        if (siteContext.site?.channels?.indexOf('collection') !== -1) {
            link = "/menu/collection";
        } else if (siteContext.site.channels.indexOf('delivery') !== -1) {
            link = "/menu/delivery";

        } else if (siteContext.site.channels.indexOf('dine_in') !== -1) {
            link = "/menu/dine-in";
        }

    }

    const logoutConfirm = () => {
        let confirm = window.confirm("Are you sure you would like to log out?");
        if (confirm) {
            logout(() => history.push("/logout"));
        }
    }

    return (
        <div className="h-100 text-center">
            <Link to="/"><img className="mt-3" src={logo} width="34" height="34" alt="Orderswift Logo" /></Link>


            <ul className="nav--bottom d-block w-100 p-2" style={{ fontSize: '22px' }} id="account-navbar">
                <li><Link to="/"><i className="fa fa-lg fa-home"></i></Link></li>
                <li><Link to="/faqs"><i className="fa fa-lg fa-question-circle"></i></Link></li>
                <li><Link to={link}><i className="fa fa-list"></i></Link></li>
                {siteContext.site?.see_ops ? <li><Link to="/settings"><i className="fa fa-lg fa-cog"></i></Link></li> : <li><a href="#" onClick={logoutConfirm}><i className="fa fa-lg fa-sign-out"></i></a></li>}

            </ul>
        </div>
    );
}

export default Navbar;