import React, { useRef } from 'react';
import { Route, RouteProps, Switch, useParams } from 'react-router-dom';
import logo from '../../assets/orderswift-logo.png';
import { TOrder } from '../../contexts/orders/order-context';
import useOrderContext from '../../contexts/orders/useOrderContext';
import useSiteContext from '../../contexts/site/useSiteContext';
import OrderViewLayout from '../../layouts/orders/OrderViewLayout';
import OrderActionButton from './components/OrderActionButton';
import OrderReceipt from './components/OrderReceipt';
import RefundButton from './components/refund/RefundButton';

type OrderViewProps = {
    orders: TOrder[] | null | undefined;
}

const OrderView = ({ orders }: OrderViewProps) => {
    const orderContext = useOrderContext();
    const siteContext = useSiteContext();
    const componentRef = useRef<any>();

    const { ref } = useParams<any>();

    const renderReceiptFooterButtons = () => {
        let buttons: JSX.Element[] = [
            <OrderActionButton key="btn-action" order={orderContext.order} />
        ];

        if (siteContext.site?.see_refund && orderContext.order) {
            buttons = [
                ...buttons,
                <RefundButton
                    key="btn-refund"
                    order={orderContext.order}
                    refundModal={orderContext.refundModal}
                    setRefundModal={orderContext.setRefundModal}
                />
            ]
        }


        return buttons.map((btn) => btn);
    }

    const fetchOrder = (ref: string) => {
        const order = orders?.find((order) => order.ref === ref);

        if (!order) {
            orderContext.setOrder(null);
        }

        return order;
    }

    if (!orderContext.order || ref !== orderContext.order.ref || !fetchOrder(ref)) {
        return (
            <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                    <div className={'col-12'}>
                        <img src={logo} className={'w-25 mx-auto d-block'}></img>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Switch>
            <Route path="/" exact={true}>
                <div className="container h-100">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className={'col-12'}>
                            <img src={logo} className={'w-25 mx-auto d-block'}></img>
                        </div>
                    </div>
                </div>
            </Route>
            <Route path="/orders/:ref" component={
                (props: RouteProps) => {
                    return (
                        <OrderViewLayout footer={renderReceiptFooterButtons()}>
                            <>
                                {/* @ts-ignore */}
                                <OrderReceipt order={fetchOrder(ref)} print={siteContext.site?.see_print ?? false} />
                            </>
                            {/* <OrderView {...props} orders={orders} getOrders={getOrders} /> */}

                        </OrderViewLayout>
                    )
                }}
            />
        </Switch>

    );
}

export default OrderView;