import React from 'react';
import '../assets/css/main.css';
import AuthenticationContext from '../contexts/auth/authentication-context';
import useAuthentication from '../contexts/auth/useAuthentication';
import Routes from '../routes';
import CacheBuster from 'react-cache-buster';
import { version } from '../../package.json';

const App = () => {
	const bustCache = process.env.REACT_APP_CACHE_BUST === 'true';
	const authActions = useAuthentication();
	return (
		<CacheBuster
			currentVersion={version}
			isEnabled={bustCache} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			//loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
		>

			<AuthenticationContext.Provider value={authActions}>
				<Routes></Routes>
			</AuthenticationContext.Provider>

		</CacheBuster>
	);
}

export default App;

