import React from 'react';
import AppLayout from '../../layouts/app/AppLayout';

const FaqsPage = () => {
    return (
        <AppLayout>
        <div className="col view--faqs p-4">
            <img className="mx-auto d-block mt-4 mb-4" src="https://www.orderswift.com/img/orderswift-logo-red.png" width="150px" />
            
            <h3>Site operations FAQ</h3>

            <p><strong>Do we need to close sites to ordering every night?</strong><br />
            No. Your site's operational hours are programmed into the Orderswift platform, so customers can only collect orders between these hours. Closing sites prevents customers from placing an order when it is convenient for them, causing lost orders.</p>
            
            <p><strong>What should we do if a site needs to close urgently?</strong><br />
            Sites can be closed in real-time from settings screen in the Restaurant App. Please remember to reopen the site from the Restaurant Dashboard once the site is operational again.</p>
           
            <p><strong>What should we do if an item/items are out of stock?</strong><br />
            If a site is out of stock of an item, it can be turned off from the menu screen in the Restaurant App. If a site is unable to fulfill an order, we recommend calling the customer to discuss options/alternatives. It is also possible to issue the customer with a refund for all or part of the order.</p>
           
            <p><strong>How do we issue a customer with a refund?</strong><br />
            Refunds can be issued through the Restaurant Dashboard ({'Home > Orders > Order > Refund'}) up to five days after the order has been placed.</p>
            
            <p><strong>What should we do if a customer reports that they did not receive an email receipt for their order?</strong><br />
            We send an email receipt for every order placed through Orderswift. However, some businesses email block senders. If a duplicate email is required, a Platform Owner can contact support@orderswift.com to request this. </p>
           
            <p><strong>What should we do if we have been logged out of the app and/or if the app is not working?</strong><br />
            Please email support@orderswift.com, giving as much detail about the issue as possible. </p>
        </div>
        </AppLayout>
    );
}

export default FaqsPage;