import React from 'react';

type SelectProps = {
    label: string;
    options: JSX.Element[];
    value: any;
    handleChange: (val: number) => void
}

const Select = ({ label, options, value, handleChange }: SelectProps) => {

    const changeHandler = (e: React.ChangeEvent<HTMLSelectElement> ) => {
        handleChange(e.target.value as any);
    }

    return (
        <div className="row mt-2">
            <p className="col pt-1">{label}</p>
            <select className="form-control selectpicker col-4" value={value} onChange={changeHandler}>
                {options}
            </select>
        </div>
    );
}

export default Select;