import React, { useEffect } from 'react';
import Switch from '../../../components/form/Switch';
import LoadingWheel from '../../../components/misc/LoadingWheel';
import { TModifier, TModifierGroup } from '../../../contexts/menu/menu-context';
import useMenuContext from '../../../contexts/menu/useMenuContext';

const ModifierView = () => {
    const menuContext = useMenuContext();

    useEffect(() => {
        menuContext.setLoading(true);
        menuContext.setMenu(null);
        menuContext.setModifiers(null);
        menuContext.fetchModifiers();
    }, []);

    const renderModifierGroups = (groups: TModifierGroup[]) => {
        if (!groups) {
            return null;
        }

        return groups.map((group) => {
            return (
                <div key={group.id}>
                    <p className="text-danger mb-1 mt-5"><b>{group.name}</b></p>
                    { renderModifiers(group.modifiers)}
                </div>

            );
        })
    }

    const renderModifiers = (modifiers: TModifier[]) => {
        if (!modifiers) {
            return null;
        }

        return modifiers.map((modifier) => {
            let visible = !modifier.is_visible;
            return (
                <Switch key={modifier.id} name={modifier.name} checked={modifier.is_visible} handleClick={() => menuContext.updateMenuModifier(modifier, visible)}></Switch>
            );
        })

    }

    if (menuContext.loading) {
        return (
            <LoadingWheel />
        );
    }

    return (
        <>
            <h4>Modifiers</h4>
            <hr></hr>
            {menuContext.modifiers && menuContext.modifiers.length > 0 ? renderModifierGroups(menuContext.modifiers) : <p>No modifiers found.</p>}
        </>
    );


}

export default ModifierView;