import React, { useContext } from 'react';
import SiteContext from './siteContext';

const useSiteContext = () => {
    const context = useContext(SiteContext);

    if (context === undefined) {
        throw new Error('useSiteContext must be within a SiteProvider');
    }
    
    return context;
}

export default useSiteContext;