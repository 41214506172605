import React, { useContext } from 'react';
import Switch from '../../../components/form/Switch';
import SiteContext from '../../../contexts/site/siteContext';

const OnlineSettings = () => {
    const siteContext = useContext(SiteContext);

    const toggleCollection = (enabled: boolean) => {
        siteContext.updateSite({ ...siteContext.site, collection_is_open: enabled });
    }

    const toggleDelivery = (enabled: boolean) => {
        siteContext.updateSite({ ...siteContext.site, delivery_is_open: enabled });
    }

    const toggleDineIn = (enabled: boolean) => {
        siteContext.updateSite({ ...siteContext.site, dine_in_is_open: enabled });
    }

    if (!siteContext.site) {
        return null;
    }

    return (
        <>
            {siteContext.collectionEnabled ?
                <Switch name="Collection" checked={siteContext.site.collection_is_open} handleClick={toggleCollection} />
                : null}

            {siteContext.deliveryEnabled ?
                <Switch name="Delivery" checked={siteContext.site.delivery_is_open} handleClick={toggleDelivery} />
                : null}

            {siteContext.dineinEnabled ?
                <Switch name="Dine-in" checked={siteContext.site.dine_in_is_open} handleClick={toggleDineIn} />
                : null}

            <hr/>
        </>
    );
}

export default OnlineSettings;