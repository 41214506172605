import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AuthenticationContext from '../contexts/auth/authentication-context';

type PageProps = {
    children: JSX.Element;
}

const Page = ({children}: PageProps) => {
    const history = useHistory();
    const location = useLocation();
    const auth = useContext(AuthenticationContext);

    // Logout on change in auth status
    useEffect(() => {
        if (auth.authenticated === false && location.pathname != '/login') {
            history.push('/login');
        }
    }, [auth.authenticated]);
    
    return <>{children}</>
}

export default Page;