import { Howl } from 'howler';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import OrderContext, {OrderContextData, TOrder, TRefundData} from './order-context';

// const bell = new UIfx(bellAudio);
const bell = new Howl({
	src: [require('../../assets/audio/bell-ringing-new.mp3')],
	volume: 1,
});

const OrderProvider = ({ children }: { children: JSX.Element }) => {
	const [orders, setOrders] = useState<TOrder[] | null>(null);
	const [orderError, setOrderError] = useState<string | null>(null);
	const [gridView, setGridView] = useState(true);
	const [order, setOrder] = useState<TOrder | null>(null);

	let defaultRefund = {
		show: false,
		ref: '',
		stage: 0,
		amount: 0,
		reason: '',
		reasonExtra: '',
		loading: false,
		validInput: false
	};

	const [refundModal, setRefundModal] = useState<TRefundData>(defaultRefund);

	const history = useHistory();

	const getOrders = async () => {
		let after = moment().subtract(6, "months").format("YYYY-MM-DD");
		if (process.env.NODE_ENV === 'production') {
			after = moment().subtract(1, "months").format("YYYY-MM-DD");
		}

		console.log('Fetching orders...', { charged_after: after })

		api.get<any, { data: TOrder[] }>('api/app/orders', { params: { charged_after: after } })
			.then((response) => {
				setOrderError(null);

				let newOrder = false;

				response.data.forEach((item: TOrder, i: number) => {
					if (item.status === "new") {
						newOrder = true;
						response.data.splice(i, 1);
						response.data.unshift(item);
					}
				});

				if (newOrder) {
					response.data.sort((orderA, orderB) => {
						// Show most urgent new order first
						if (orderA.status === "new" && orderB.status === "new") {
							return orderA.wanted_at > orderB.wanted_at ? 1 : -1;
						}
						return 0
					})
					console.log('New order detected.')
					bell.play();
				}

				console.log('Fetch completed.')
				setOrders(response.data);
			})
			.catch((error) => {
				// Error 😨
				console.error('Error while fetching orders...', error)

				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);

					if (error.response.status === 401) {
						history.push("/logout");
						window.alert("You have been automatically logged out.");
					} else {
						setOrderError('Error: There was an issue fetching the orders from the API. Trying again automatically in 60 seconds... (' + error.message + ')');

					}

					// else display a error banner

				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
					setOrderError('Error: A connection issue has been detected. Attempting to reconnect in 60 seconds... (' + error.message + ')');

					console.log(error.request);
					// history.push("/logout");
					// window.alert("You have been automatically logged out. (2)");	

				} else {
					// Something happened in setting up the request and triggered an Error
					console.log('Error', error.message);
					setOrderError('Error: Request error occurred. Please reload the page. (' + error.message + ')');

				}
				console.log(error.config);
			});
	}

	const orderContextData: OrderContextData = {
		orders,
		setOrders,
		getOrders,

		orderError,
		setOrderError,

		gridView,
		setGridView,

		order,
		setOrder,

		refundModal,
		setRefundModal,
	}


	return (
		<OrderContext.Provider value={orderContextData}>{children}</OrderContext.Provider>
	);
}

export default OrderProvider;