import React, { createContext } from 'react';

export type SiteContextData = {
    site: TSite | null;
    setSite: (site: TSite | null) => void;

    loading: boolean;
    setLoading: (state: boolean) => void;

    updateSite: (site: Partial<TSite>) => void;
    fetchSite: () => void;

    deliveryEnabled: boolean; 
    collectionEnabled: boolean;
    dineinEnabled: boolean;

    siteError: string | null;
    setSiteError: (err: string | null) => void;

}

export type TSite = {
    uid: string,
    channels: TSiteChannel[],
    see_refund: boolean,
    see_ops: boolean,
    see_print: boolean,
    see_prep_time: boolean,
    see_menus: boolean,
    see_capacity_collection: boolean,
    see_capacity_delivery: boolean,
    see_auto_reopen: boolean,
    available_menus: TSiteMenu[]
    collection_is_open: boolean,
    collection_menu_id: number,
    collection_prep_time: number,
    delivery_is_open: boolean,
    delivery_menu_id: number,
    delivery_prep_time: number,
    dine_in_is_open: boolean,
    dine_in_menu_id: number,
    capacity_collection: number,
    capacity_delivery: number,
}

export type TSiteChannel = 'collection' | 'delivery' | 'dine_in';
export type TSiteMenu = {
    id: number,
    name: string,
}

const SiteContext = createContext<SiteContextData>({
    site: null,
    setSite: () => {},

    loading: false,
    setLoading: () => {},
    
    updateSite: () => {},
    fetchSite: () => {},

    siteError: null,
    setSiteError: () => {},

    deliveryEnabled: false,
    collectionEnabled: false,
    dineinEnabled: false,
});

export default SiteContext;