import React from 'react';
import { isAuthenticated, logout } from '../../services/auth';
import { useHistory, Link } from 'react-router-dom';

function LogoutButton() {
	let history = useHistory();
  
	return isAuthenticated() ? (
            <a href="#" onClick={(e) => { 
                let confirm = window.confirm("Are you sure you would like to log out?");
                if (confirm) {
                    logout(() => history.push("/"));
                }
            
            }}>
                <div className="text-center">Logout</div>   
            </a>   
        ) : (<p>You are not logged in.</p>);
}

export default LogoutButton;
