import React from 'react';
import loadingImg from '../../assets/imgs/loading.gif';

const LoadingWheel = () => {
    return (
        <div className="row justify-content-center align-self-center">
            <img src={loadingImg} style={{ width: '60px' }} alt="Loading..." />
        </div>
    );
}

export default LoadingWheel;