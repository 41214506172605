import React from 'react';
import { Link, useLocation } from 'react-router-dom';

type OrderViewNav = {
    showGrid: boolean;
    setShowGrid: (show: boolean) => void;
}

const OrderViewNav = ({ showGrid, setShowGrid }: OrderViewNav) => {
	let query = useQuery();

	let activeFilter = query.get('filter') === 'active';
    let completedFilter = query.get('filter') === 'completed';
    
	let activeClasses = activeFilter || !completedFilter ? 'nav-link  font-weight-bold' : 'nav-link disabled text-dark';
	let completedClasses = completedFilter ? 'nav-link  font-weight-bold' : 'nav-link disabled';

	let showCardViewClasses = showGrid ? 'nav-link disabled' : 'nav-link';
	let showListViewClasses = !showGrid ? 'nav-link disabled' : 'nav-link';


	return (
		<>
			<ul className="nav" style={{fontSize: '1.3em'}}>
				<li className="nav-item">
					<Link to="?filter=active" className={activeClasses} href="#" style={{
						textDecoration: 'none'
					}}>Active</Link>
				</li>
				<li className="nav-item">
					<Link to="?filter=completed" className={completedClasses} href="#" style={{
						textDecoration: 'none'
					}}>Completed</Link>
				</li>
				<li className="nav-item ml-auto">
					<a className={showCardViewClasses} href="#" onClick={() => setShowGrid(false)} style={{ textDecoration: 'none' }}><i className="fa fa-th-large" aria-hidden="true"></i></a>
				</li>

				<li className="nav-item">
					<a className={showListViewClasses} href="#" onClick={() => setShowGrid(true)} style={{ textDecoration: 'none' }}><i className="fa fa-bars" aria-hidden="true"></i></a>
				</li>
			</ul>
		</>
		
	);
}

export default OrderViewNav;

export function useQuery() {
	return new URLSearchParams(useLocation().search);
}
