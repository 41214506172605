import React, { useContext, useEffect, useState } from 'react';
import api from '../../services/api';
import AuthenticationContext from '../auth/authentication-context';
import SiteContext, { SiteContextData, TSite } from './siteContext';

type SiteContextProviderProps = {
	children: JSX.Element;
}

const SiteContextProvider = ({ children }: SiteContextProviderProps) => {
	const [site, setSite] = useState<TSite | null>(null);
	const [loading, setLoading] = useState(true);
	const [siteError, setSiteError] = useState<string | null>(null);

	const { authenticated } = useContext(AuthenticationContext);

	const fetchSite = async () => {
		setLoading(true);
		setSiteError(null);

		api.get<any, { data: TSite }>('api/app/site')
			.then((response) => {
				setSite(response.data);
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	};

	const updateSite = async (updatedSite: Partial<TSite>) => {
		setLoading(true);
		setSiteError(null);

		api.put<TSite>('api/app/site', updatedSite)
			.then(async () => {
				await fetchSite();
			})
			.catch((error) => {
				console.error(error);
				if (error.response) {

					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);

					setSiteError('Error: Request error occurred. Please reload the page. (' + error.response.data.message + ')');
				}
			})
			.finally(() => setLoading(false));
	}

	const deliveryEnabled = !!(site && site.channels.indexOf('delivery') !== -1);
	const collectionEnabled = !!(site && site.channels.indexOf('collection') !== -1);
	const dineinEnabled = !!(site && site.channels.indexOf('dine_in') !== -1);


	let siteContextValue: SiteContextData = {
		site,
		setSite,

		loading,
		setLoading,

		fetchSite,
		updateSite,

		deliveryEnabled,
		collectionEnabled,
		dineinEnabled,

		siteError,
		setSiteError,
	}

	useEffect(() => {
		// console.log('site context auth check', authenticated)
		if (authenticated) {
			console.log('Fetching site...')
			siteContextValue.fetchSite()
		} else {
			siteContextValue.setSite(null);
		}
	}, [authenticated]);


	return (
		<SiteContext.Provider value={siteContextValue as any}>{children}</SiteContext.Provider>
	)
}

export default SiteContextProvider;