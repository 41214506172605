import React from 'react';
import Navbar from './components/Navbar';

type AppLayoutProps = {
    children: JSX.Element;
    banner?: JSX.Element | null;
}
const AppLayout = ({ children, banner }: AppLayoutProps) => {
    return (
        <div className="app-container">
            {banner}

            <div className="row row-eq-height h-100">
                <div className="pane pane--nav">
                    <Navbar></Navbar>
                </div>
                <div className="col view--orders">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default AppLayout;