import React, { useState } from 'react';
import api from '../../services/api';
import { TSiteChannel } from '../site/siteContext';
import MenuContext, { MenuContextData, TMenu, TModifier, TModifierGroup, TMenuItem } from './menu-context';

type MenuProviderProps = {
    children: JSX.Element;
}

const MenuProvider = ({ children }: MenuProviderProps) => {
    const [menu, setMenu] = useState<TMenu | null>(null);
    const [modifiers, setModifiers] = useState<TModifierGroup[] | null>(null);
    const [loading, setLoading] = useState(true);

    const fetchMenu = async (channel: TSiteChannel) => {
        api.get('api/app/menu', {params: { channel: channel}})
        .then((response) => {
            setLoading(false);
            setMenu(response.data);
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        });
    };

    const updateMenuItem = async (channel: TSiteChannel, item: TMenuItem, visible: boolean) => {
        api.put('api/app/menu', {
            item_id: item.id,
            is_visible: visible
        })
        .then(async () => {
            await fetchMenu(channel);
        })
        .catch((error) => {

            if (error.response.status === 422) {
                alert(error.response.data.errors[0]);
            }

            console.error(error);
        });
    }

    const fetchModifiers = async () => {
        api.get('api/app/menu/modifiers')
        .then((response) => {
            setLoading(false);
            setModifiers(response.data);
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        });
    };

    const updateMenuModifier = async (modifier: TModifier, visible: boolean) => {
        api.put('api/app/menu/modifier', {
            modifier_id: modifier.id,
            is_visible: visible
        })
        .then(async () => {
            await fetchModifiers();
        })
        .catch((error) => {

            if (error.response.status === 422) {
                alert(error.response.data.errors[0]);
            }

            console.error(error);
        });
    }
    
	const menuContextData: MenuContextData = {
        menu,
        setMenu,

        modifiers,
        setModifiers,

        loading,
        setLoading,

        fetchMenu,
        fetchModifiers,
        updateMenuItem,
        updateMenuModifier,
	}

    return (
        <MenuContext.Provider value={menuContextData as any}>{children}</MenuContext.Provider>
    )
}

export default MenuProvider;