import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import SiteContext, { TSiteChannel } from '../../../contexts/site/siteContext';

type MenuTabProps = {
    channel?: TSiteChannel,
    showModifiers?: boolean
}

const MenuTabs = ({channel, showModifiers = false}: MenuTabProps) => {
    const siteContext = useContext(SiteContext);

    const isChannelEnabled = (c?: TSiteChannel) => {
        if (!c || !siteContext.site) {
            return false;
        }

        return siteContext.site.channels.indexOf(c) !== -1;
    }

    let collectionClasses = !channel && !showModifiers || channel === 'collection' ? 'nav-link disabled' : 'nav-link';
	let deliveryClasses = isChannelEnabled(channel) && channel === 'delivery' ? 'nav-link disabled' : 'nav-link';
    let dineinClasses =  channel === 'dine_in' ? 'nav-link disabled' : 'nav-link';
    let modifierClasses =  showModifiers ? 'nav-link disabled' : 'nav-link';

    return (
        <ul className="nav">
            {isChannelEnabled('collection') ? (<li className="nav-item">
				<Link to="/menu/collection" className={collectionClasses}>Collection</Link>
			</li>) : null}

            {isChannelEnabled('delivery') ? (
                <li className="nav-item">
                    <Link to="/menu/delivery" className={deliveryClasses}>Delivery</Link>
                </li>
            ) : null}

            {isChannelEnabled('dine_in') ? (
                <li className="nav-item">
                    <Link to="/menu/dine-in" className={dineinClasses}>Dine In</Link>
                </li>
            ) : null}

            <li className="nav-item">
                <Link to="/menu/modifiers" className={modifierClasses}>Modifiers</Link>
            </li>

		</ul>
    )
}

export default MenuTabs;