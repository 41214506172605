import React from 'react';

type ErrorBannerProps = {
    children: string | null;
    onRetry: () => void;
}

const ErrorBanner = ({ children, onRetry }: ErrorBannerProps) => {

    if (!children) {
        return null;
    } 

    return (
        <div className={'alert alert-danger m-0'}>
            <h5 className={'m-0'}>{children} <div className={'btn btn-sm btn-danger'} onClick={onRetry}>Retry</div></h5>
        </div>
    );
}

export default ErrorBanner;