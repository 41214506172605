import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Switch from '../../../components/form/Switch';
import LoadingWheel from '../../../components/misc/LoadingWheel';
import { TMenu, TMenuItem } from '../../../contexts/menu/menu-context';
import useMenuContext from '../../../contexts/menu/useMenuContext';
import { TSiteChannel } from '../../../contexts/site/siteContext';
import { capitalizeFirstLetter } from '../../../services/utils';

const MenuView = ({ channel }: { channel: TSiteChannel }) => {
    const menuContext = useMenuContext();

    useEffect(() => {
        menuContext.setLoading(true);
        menuContext.setMenu(null);
        menuContext.setModifiers(null);
        menuContext.fetchMenu(channel);
    }, [channel]);

    const renderCategories = (categories: TMenu) => {
        if (!categories) {
            return null;
        }

        return categories.map((category) => {
            return (
                <div key={category.id}>
                    <p className="text-danger mb-1 mt-5"><b>{category.name}</b></p>
                    { renderItems(category.items)}
                </div>
            );
        })
    }

    const renderItems = (items: TMenuItem[]) => {
        if (!items || !channel) {
            return null;
        }

        return items.map((item) => {
            let visible = !item.is_visible;
            return (
                <Switch key={item.id} name={item.name} checked={item.is_visible} handleClick={() => menuContext.updateMenuItem(channel, item, visible)}></Switch>
            );
        })

    }

    if (menuContext.loading) {
        return (
            <LoadingWheel />
        );
    }

    return (
        <>
            <h4>{channel === 'dine_in' ? 'Dine In' : capitalizeFirstLetter(channel ?? '')} Menu</h4>
            <hr></hr>
            {menuContext.menu ? renderCategories(menuContext.menu) : <p>Please select a menu for this channel in the <Link to="/settings">Settings</Link>.</p>}
            <hr></hr>
        </>
    );


}

export default MenuView;