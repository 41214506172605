import React, { useState } from 'react';
import { TOrder, TOrderStatus } from '../../../contexts/orders/order-context';
import useOrderContext from '../../../contexts/orders/useOrderContext';
import api from '../../../services/api';

type OrderActionButtonProps = {
    order: TOrder | null | undefined;
}

const OrderActionButton = ({order}: OrderActionButtonProps) => {
    const orderContext = useOrderContext()
    const [disabled, setDisabled] = useState(false);

    let updateOrderStatus = (ref: string, status: TOrderStatus) => {
        setDisabled(true);

        let confirmed = window.confirm(`Are you sure you would like to mark this order as ${status}?`);

        if (!confirmed) {
            setDisabled(false);
            return false;
        }

        api.put('api/app/order', {
            ref: ref,
            status: status
        }).then(async () => {
            await orderContext.getOrders();
            setDisabled(false);
        })
        .catch((error: any) => {
            console.error(error);
            setDisabled(false);
        });
    }

    if (!order) {
        return null;
    }

    if (disabled) {
        return <button className="btn btn-success btn-lg btn-block" disabled><i className="fa fa-spinner fa-pulse"></i></button>
    }

    switch(order.status) {
        case 'new':
            return <button className="btn btn-success btn-lg btn-block" onClick={(e) => updateOrderStatus(order.ref, 'accepted')}><b>MARK AS SEEN</b></button>
        case 'accepted':
            return <button className="btn btn-success btn-lg btn-block" onClick={(e) => updateOrderStatus(order.ref, 'completed')}><b>MARK AS COMPLETED</b></button>
        default:
            return null;
    }
}

export default OrderActionButton