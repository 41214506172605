import React, {useContext, useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import LogoutButton from '../../components/auth/LogoutButton';
import LoadingWheel from '../../components/misc/LoadingWheel';
import SiteContext from '../../contexts/site/siteContext';
import AppLayout from '../../layouts/app/AppLayout';
import MenuSettings from './sections/MenuSettings';
import OnlineSettings from './sections/OnlineSettings';
import PrepSettings from './sections/PrepSettings';
import Capacity from './sections/Capacity';
import ErrorBanner from "../Orders/components/ErrorBanner";

const SettingsPage = () => {
    const siteContext = useContext(SiteContext);

    useEffect(() => {
        if (siteContext) {
            siteContext.fetchSite();

            let settingsTimer = setInterval(siteContext.fetchSite, 1000 * 60);
            return () => clearInterval(settingsTimer);
        }
    }, []);

    if (!siteContext.site) {
        return <LoadingWheel />;
    }

    if ( !siteContext.site.see_ops ) {
        return (
            <Redirect to="/" />
        );
    }

    const errorBanner = <ErrorBanner onRetry={siteContext.fetchSite}>{siteContext.siteError}</ErrorBanner>

    return (
        <AppLayout banner={errorBanner}>
            <div className="col view--settings">
                <div className="container wrapper--settings">
                    <div className="component-light">

                        <h4>Settings</h4>
                        <p>Alter the settings for this site. {( siteContext.site.see_auto_reopen ? 'Please note: closing a site will only do so for the current day. For longer site closures, please do this in the Operator Dashboard.' : '' )}</p>

                        <hr/>

                        <OnlineSettings/>

                        <PrepSettings />

                        <Capacity />

                        <MenuSettings />

                        <LogoutButton/>

                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default SettingsPage;