import React, { createContext } from 'react';
import { TSiteChannel } from '../site/siteContext';

export type TMenu = TMenuCategory[];

export type TMenuCategory = {
    id: string,
    name: string,
    items: TMenuItem[],
}

export type TMenuItem = {
    id: string,
    name: string,
    is_visible: boolean,
}

export type TModifierGroup = {
    id: string,
    name: string,
    modifiers: TModifier[],
}

export type TModifier = {
    id: string,
    name: string,
    is_visible: boolean,
}

export type MenuContextData = {
    menu: TMenu | null;
    setMenu: (menu: TMenu | null) => void;

    modifiers: TModifierGroup[] | null;
    setModifiers: (modifiers: TModifierGroup[] | null) => void;

    loading: boolean;
    setLoading: (state: boolean) => void;

    fetchMenu: (channel: TSiteChannel) => void;
    fetchModifiers: () => void;
    updateMenuItem: (channel: TSiteChannel, item: TMenuItem, visible: boolean) => void;
    updateMenuModifier: (modifier: TModifier, visible: boolean) => void;
}

const MenuContext = createContext<MenuContextData | undefined>(undefined);

export default MenuContext;