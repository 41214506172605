import React, { useContext } from 'react';
import Select from '../../../components/form/Select';
import SiteContext from '../../../contexts/site/siteContext';
import { capitalizeFirstLetter } from '../../../services/utils';

const MenuSettings = () => {
    const siteContext = useContext(SiteContext);

    const changeCollectionMenu = (value: number) => {
        siteContext.updateSite({ ...siteContext.site, collection_menu_id: value });
    }

    const changeDeliveryMenu = (value: number) => {
        siteContext.updateSite({ ...siteContext.site, delivery_menu_id: value });
    }

    const changeDineinMenu = (value: number) => {
        siteContext.updateSite({ ...siteContext.site, dine_in_menu_id: value });
    }

    if ( !siteContext.site || !siteContext.site.see_menus ) {
        return null;
    }

    let options = [];
    options = siteContext.site.available_menus.map((menu) => (
        <option key={menu.id + 'menu'} value={menu.id}>{menu.name}</option>
    ));

    options.unshift(<option key={null} disabled value="-1">Select Menu</option>)

    return (
        <>
            <b className="text-muted">Menus</b>

            {siteContext.collectionEnabled ?
                <Select options={options} label={capitalizeFirstLetter("collection")} value={siteContext.site.collection_menu_id ?? '-1'} handleChange={changeCollectionMenu} />
                : null}
            {siteContext.deliveryEnabled ?
                <Select options={options} label={capitalizeFirstLetter("delivery")} value={siteContext.site.delivery_menu_id ?? '-1'} handleChange={changeDeliveryMenu} />
                : null}
            {siteContext.dineinEnabled ?
                <Select options={options} label={capitalizeFirstLetter("dine-in")} value={siteContext.site.dine_in_menu_id ?? '-1'} handleChange={changeDineinMenu} />
                : null}

            <hr/>
        </>
    );
}

export default MenuSettings;