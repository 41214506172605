import React, {useContext, useEffect, useRef, useState} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {TOrder, TOrderStatus} from '../../contexts/orders/order-context';
import useOrderContext from '../../contexts/orders/useOrderContext';
import OrdersLayout from '../../layouts/orders/OrdersLayout';
import ErrorBanner from './components/ErrorBanner';
import OrdersViewNav from './components/OrdersViewNav';
import OrdersView from './OrdersView';
import OrderView from './OrderView';

const OrdersPage = () => {
    const context = useOrderContext();
    const { ref } = useParams<any>();

    useEffect(() => {
        context.getOrders();

        let ordersTimer = setInterval(context.getOrders, 1000 * 10);
        return () => clearInterval(ordersTimer);
    }, []);

    useEffect(() => {
        if (context) {
            context.setOrder(context?.orders?.find((order) => order.ref === ref) ?? null);
        }
    }, [context.orders, ref]);
    
    const activeOrdersFilter = () => {
        if (!context.orders) {
            return null;
        }
        return context.orders.filter((order) => (isActiveStatus(order.status)));
    }

    const completedOrdersFilter = () => {
        if (!context.orders) {
            return null;
        }

        return context.orders.filter((order) => (isCompletedStatus(order.status))).reverse();
    }

    const filter = useQuery().get('filter');
    const orders = filter === 'active' || !filter
        ? activeOrdersFilter() : completedOrdersFilter();

    const errorBanner = <ErrorBanner onRetry={context.getOrders}>{context.orderError}</ErrorBanner>
    
    return (
        <OrdersLayout
            banner={errorBanner}
            orderView={(
                <OrderView orders={orders} />
            )}>
            <>
                <OrdersViewNav showGrid={context.gridView} setShowGrid={context.setGridView} />

                <OrdersView orders={orders} />
            </>
        </OrdersLayout>
    );
}

export default OrdersPage;

function isCompletedStatus(status: TOrderStatus) {
    return status === 'completed' || status === 'cancelled';
}

function isActiveStatus(status: TOrderStatus) {
    return status === 'new' || status === 'accepted';
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
